var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.queryParams }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "关键字搜索：" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.queryParams.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParams, "keyword", $$v)
                      },
                      expression: "queryParams.keyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.requestData(1)
                        }
                      }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleEdit(-100, null)
                        }
                      }
                    },
                    [_vm._v("添加角色")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.data, border: "", stripe: "" }
        },
        [
          _c("el-table-column", { attrs: { prop: "ID", label: "ID" } }),
          _c("el-table-column", { attrs: { prop: "name", label: "角色名称" } }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(scope.$index, scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(scope.$index, scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function($event) {
                            return _vm.handlePermissionEdit(scope.row)
                          }
                        }
                      },
                      [_vm._v("设置权限")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.queryParams.page,
          "page-size": _vm.queryParams.pageSize,
          layout: "total, prev, pager, next",
          total: _vm.total
        },
        on: {
          "current-change": function($event) {
            return _vm.requestData(null)
          },
          "update:currentPage": function($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:current-page": function($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          }
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "角色增加/修改",
            visible: _vm.dialogNewVisible,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogNewVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "newForm",
              attrs: {
                model: _vm.newForm,
                rules: _vm.rules,
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "角色名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.newForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "name", $$v)
                      },
                      expression: "newForm.name"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: {
                    click: function($event) {
                      return _vm.handleSave("newForm")
                    }
                  }
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogNewVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          ref: "drawer",
          attrs: {
            "destroy-on-close": "",
            title: "权限设置",
            visible: _vm.dialogPermissionVisible,
            direction: "rtl",
            size: "50%",
            withHeader: false,
            "custom-class": "demo-drawer"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogPermissionVisible = $event
            },
            close: _vm.onPermissionDialogClose
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "demo-drawer__content",
              staticStyle: { "margin-top": "15px" }
            },
            [
              _c(
                "el-form",
                { ref: "permissionForm", attrs: { model: _vm.permissionForm } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "type", "label-width": "10px" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "small" },
                          on: { change: _vm.permissionTypeChanged },
                          model: {
                            value: _vm.permissionForm.type,
                            callback: function($$v) {
                              _vm.$set(_vm.permissionForm, "type", $$v)
                            },
                            expression: "permissionForm.type"
                          }
                        },
                        [
                          _c("el-radio-button", { attrs: { label: 1 } }, [
                            _vm._v("菜单权限")
                          ]),
                          _c("el-radio-button", { attrs: { label: 2 } }, [
                            _vm._v("接口权限")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { prop: "permission_ids", "label-width": "10px" }
                    },
                    [
                      _c("el-tree", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.permissionLoading,
                            expression: "permissionLoading"
                          }
                        ],
                        ref: "tree",
                        attrs: {
                          data: _vm.permissionList,
                          props: { children: "children", label: "name" },
                          "show-checkbox": "",
                          "default-expand-all": "",
                          "node-key": "uuid",
                          "highlight-current": ""
                        }
                      })
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { "label-width": "20px" } }, [
                    _c(
                      "div",
                      { staticClass: "demo-drawer__footer" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              loading: _vm.permissionSubmitting
                            },
                            on: {
                              click: function($event) {
                                return _vm.handlePermissionSave(
                                  "permissionForm"
                                )
                              }
                            }
                          },
                          [_vm._v("确定 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                _vm.dialogPermissionVisible = false
                              }
                            }
                          },
                          [_vm._v("取 消")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { deleteRole, editRole, getPermissionList, getRoleList, setRolePermission } from '@/api/permission';
export default {
  name: "roles",
  data: function data() {
    return {
      data: [],
      queryParams: {
        keyword: "",
        page: 1,
        pageSize: 20
      },
      loading: false,
      total: 0,
      editIndex: '',
      formLabelWidth: '120px',
      submitting: false,
      dialogNewVisible: false,
      newForm: {
        id: 0,
        name: ''
      },
      rules: {
        name: [{
          required: true,
          message: '角色名称 必填'
        }]
      },
      permissionSubmitting: false,
      dialogPermissionVisible: false,
      permissionForm: {
        role_id: 0,
        type: 1,
        permission_ids: []
      },
      permissionList: [],
      permissionLoading: false
    };
  },
  created: function created() {
    this.requestData(1);
  },
  methods: {
    requestData: function requestData(page) {
      var _this = this;

      if (page !== null) {
        this.queryParams.page = page;
      }

      this.loading = true;
      getRoleList(_objectSpread({}, this.queryParams)).then(function (res) {
        _this.loading = false;

        if (res.code === 0) {
          _this.data = res.data.list;
          _this.total = res.data.total;
        }
      });
    },
    handleDelete: function handleDelete(index, row) {
      var _this2 = this;

      this.$confirm('您确定要删除这项数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteRole({
          id: row.ID
        }).then(function (res) {
          if (res.code === 0) {
            _this2.$message.success('删除成功');

            _this2.data.splice(index, 1);
          }
        });
      }).catch(function () {
        _this2.$message.info('已取消删除');
      });
    },
    handleEdit: function handleEdit(index, row) {
      this.editIndex = '';

      if (row) {
        this.newForm = {
          id: row.ID,
          name: row.name
        };
        this.editIndex = index;
      } else {
        this.newForm = {
          id: 0,
          name: ""
        };
      }

      this.dialogNewVisible = true;
    },
    handleSave: function handleSave(formName) {
      var _this3 = this;

      this.submitting = true;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var form = _objectSpread({}, _this3.newForm);

          editRole(form).then(function (res) {
            _this3.submitting = false;

            if (res.code === 0) {
              _this3.$message.success('编辑成功');

              _this3.dialogNewVisible = false;

              if (_this3.editIndex === "") {
                _this3.data.unshift(res.data);
              } else {
                var arr = _this3.data.slice();

                arr[_this3.editIndex] = res.data;
                _this3.data = arr;
              }
            }
          });
        } else {
          _this3.submitting = false;
        }
      });
    },
    onPermissionDialogClose: function onPermissionDialogClose() {
      this.permissionForm = {
        role_id: 0,
        type: 1,
        permission_ids: []
      };
    },
    permissionTypeChanged: function permissionTypeChanged() {
      this.getRolePermission();
    },
    getRolePermission: function getRolePermission() {
      var _this4 = this;

      this.permissionLoading = true;
      getPermissionList(_objectSpread(_objectSpread({}, this.permissionForm), {}, {
        page: -1,
        pageSize: -1
      })).then(function (res) {
        _this4.permissionLoading = false;

        if (res.code === 0) {
          _this4.permissionList = res.data.list;

          _this4.$refs.tree.setCheckedKeys(_this4.setCheckedKeys(res.data.role_has_permission || []));
        }
      });
    },
    handlePermissionEdit: function handlePermissionEdit(row) {
      this.onPermissionDialogClose();
      this.permissionForm = {
        role_id: row.ID,
        type: 1,
        permission_ids: []
      };
      this.dialogPermissionVisible = true;
      this.getRolePermission();
    },
    setCheckedKeys: function setCheckedKeys(arrayIds) {
      var ids = [];

      if (arrayIds.length > 0) {
        for (var i = 0; i < arrayIds.length; i++) {
          ids.push(this.permissionForm.type + '_2_' + arrayIds[i]);
        }
      }

      return ids;
    },
    getCheckedKeys: function getCheckedKeys() {
      var ids1 = this.$refs.tree.getCheckedKeys() || [];
      var ids2 = this.$refs.tree.getHalfCheckedKeys() || [];
      var ids = ids1.concat(ids2);
      var arr = [];

      if (ids.length > 0) {
        for (var i = 0; i < ids.length; i++) {
          arr = ids[i].split('_');

          if (arr[0] == 2) {
            if (arr[1] == 2) {
              this.permissionForm.permission_ids.push(parseInt(arr[2]));
            }
          } else if (arr[0] == 1) {
            this.permissionForm.permission_ids.push(parseInt(arr[2]));
          }
        }
      }
    },
    handlePermissionSave: function handlePermissionSave(formName) {
      var _this5 = this;

      this.getCheckedKeys();
      this.permissionSubmitting = true;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var form = _objectSpread({}, _this5.permissionForm);

          setRolePermission(form).then(function (res) {
            _this5.permissionSubmitting = false;

            if (res.code === 0) {
              _this5.$message.success('设置成功');

              _this5.dialogPermissionVisible = false;
            }
          });
        } else {
          _this5.permissionSubmitting = false;
        }
      });
    }
  }
};